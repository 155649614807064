var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-wrapper',{staticClass:"space-y-6"},[_c('create-order-form',{attrs:{"valid":_vm.order.valid,"with-destination-office":false,"with-origin-warehouse":false,"with-origin-office":"","with-origin-office-area":false,"with-options":false},model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}}),(_vm.canCreateOrderDetail)?_c('create-order-detail-form',{attrs:{"type":"product","disabled":_vm.order.valid,"order-id":_vm.order.id,"destination-office":{ id: _vm.me.office_id, code: _vm.me.office_code },"origin-office":{
        id: _vm.order.originOffice.id,
        code: _vm.order.originOffice.code,
      },"buyer-type":{
        id: _vm.order.buyerType.id,
        code: _vm.order.buyerType.code,
      },"area":{
        id: _vm.order.area.id,
        code: _vm.order.area.code,
      },"create-order-attributes":{
        order_type: 'purchase',
        origin_warehouse_id: _vm.order.originOffice.currentWarehouse.id,
        destination_warehouse_id: _vm.me.current_warehouse,
      },"columns":[
        'code',
        'name',
        'product_type',
        'qty',
        'price',
        'total_price',
        'action',
        'qr'
      ]},on:{"order-created":_vm.onOrderCreated},model:{value:(_vm.orderDetails),callback:function ($$v) {_vm.orderDetails=$$v},expression:"orderDetails"}}):_vm._e(),_c('div',{staticClass:"flex justify-end gap-x-2"},[(!_vm.order.valid)?_c('base-button',{attrs:{"disabled":!_vm.canValidate},on:{"click":_vm.onValidate}},[_vm._v("Validasi")]):_vm._e(),_c('base-button',{attrs:{"to":{ name: 'pembelian' },"color":"white"}},[_vm._v("Kembali")])],1)],1),_c('view-order-modal',{attrs:{"visible":_vm.viewOrderModalVisible,"order-id":_vm.order.id,"with-payment":false,"label":"Pembelian"},on:{"validated":function($event){return _vm.$router.push({ name: 'pembelian' })},"close":function($event){return _vm.$router.push({ name: 'pembelian' })}}}),_c('validate-order-confirmation',{attrs:{"order-id":_vm.order.id,"visible":_vm.confirmValidateOrderVisible},on:{"close":function($event){_vm.confirmValidateOrderVisible = false},"confirmed":_vm.onValidateConfirmed}}),(_vm.loading)?_c('loading'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }