<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <base-card title="Informasi Supplier">
        <div class="grid gap-4 border-t pt-5 sm:grid-cols-2">
          <base-input
            inset
            label="Kode PO"
            placeholder="Kode PO"
            disabled
            :value="
              stockMovement.data.relationships['order'].data
                ? getSingleIncluded(
                    stockMovement,
                    stockMovement.data.relationships['order'].data.id
                  ).attributes.origin_code
                : '-'
            "
          />
          <base-input
            inset
            label="Tanggal"
            placeholder="Tanggal"
            disabled
            :value="stockMovement.data.attributes.updatedAt | toCurrency"
          />
          <base-input
            inset
            label="Kode Supplier"
            placeholder="Kode Supplier"
            disabled
          >
            <p class="mt-1 text-sm">
              {{
                stockMovement.data.relationships['origin-office'].data
                  ? getSingleIncluded(
                      stockMovement,
                      stockMovement.data.relationships['origin-office'].data.id
                    ).attributes.code
                  : '-'
              }}
            </p>
          </base-input>
          <base-input
            inset
            label="Nama Supplier"
            placeholder="Nama Supplier"
            disabled
          >
            <p class="mt-1 text-sm">
              {{
                stockMovement.data.relationships['origin-office'].data
                  ? getSingleIncluded(
                      stockMovement,
                      stockMovement.data.relationships['origin-office'].data.id
                    ).attributes.name
                  : '-'
              }}
            </p>
          </base-input>
        </div>
      </base-card>

      <base-card
        title="Informasi Barang Keluar"
        description="Masukkan detail barang keluar. Klik pada baris untuk menambahkan batch"
      >
        <div class="border-t pt-5">
          <datatable :columns="tableColumns">
            <template #tbody="{ classes }">
              <template v-for="(item, index) in shipmentForm">
                <tr :key="index" :class="classes.tr">
                  <td :class="[classes.td, 'font-bold text-gray-900']">
                    {{ item.productCode }}
                    <span v-if="item.productType === 'free'">(Promo)</span>
                  </td>
                  <td :class="classes.td">{{ item.productName }}</td>
                  <td :class="classes.td">
                    <input
                      type="text"
                      class="focus:outline-none m-0 border-0 p-0 text-sm placeholder-gray-400 focus:border-0 focus:ring-0"
                      placeholder="Masukkan Jumlah"
                      v-model="shipmentForm[index].qty"
                      @change="onChangeDetailQty(index)"
                    />
                  </td>
                  <td :class="classes.td">
                    {{ item.productPrice | toCurrency }}
                  </td>
                  <td :class="classes.td">
                    {{ item.totalPrice | toCurrency }}
                  </td>
                  <td :class="classes.td">
                    <Icon
                      icon="heroicons:x-mark-20-solid"
                      class="mx-auto h-4 w-4 cursor-pointer text-red-300 hover:text-red-600"
                    />
                  </td>
                </tr>

                <tr
                  v-for="(batch, batchIndex) in item.batches"
                  :key="`batch-${index}-${batchIndex}`"
                  :class="[classes.tr, 'bg-gray-50']"
                >
                  <td :class="classes.td">
                    <button
                      v-if="batchIndex === item.batches.length - 1"
                      class="flex w-full justify-center"
                      @click="onAddBatch(index)"
                    >
                      <Icon
                        class="mx-auto h-6 w-6 text-green-600 hover:text-green-500"
                        icon="heroicons:plus-circle-20-solid"
                      />
                    </button>
                  </td>
                  <td :class="classes.td">
                    <input
                      type="text"
                      class="focus:outline-none m-0 border-0 bg-transparent p-0 text-sm placeholder-gray-400 focus:border-0 focus:ring-0"
                      placeholder="Masukkan No. Batch"
                      v-model="shipmentForm[index].batches[batchIndex].no"
                      @change="onChangeBatch(index, batchIndex)"
                    />
                  </td>
                  <td :class="classes.td">
                    <input
                      type="text"
                      class="focus:outline-none m-0 border-0 bg-transparent p-0 text-sm placeholder-gray-400 focus:border-0 focus:ring-0"
                      placeholder="Masukkan Jumlah"
                      :disabled="
                        qrScan.index === index &&
                        qrScan.batchIndex === batchIndex
                      "
                      v-model="shipmentForm[index].batches[batchIndex].qty"
                      @change="onChangeBatch(index, batchIndex)"
                    />
                  </td>
                  <td :class="classes.td">
                    <input
                      type="date"
                      class="focus:outline-none m-0 border-0 bg-transparent p-0 text-sm placeholder-gray-400 focus:border-0 focus:ring-0"
                      placeholder="Masukkan Jumlah"
                      v-model="shipmentForm[index].batches[batchIndex].expire"
                      @change="onChangeBatch(index, batchIndex)"
                    />
                  </td>
                  <td :class="classes.td">
                    <base-button
                      v-if="shipmentForm[index].qrcodes.length"
                      :color="
                        qrScan.index === index &&
                        qrScan.batchIndex === batchIndex
                          ? 'warning'
                          : 'white'
                      "
                      size="sm"
                      @click="onStartScan(index, batchIndex)"
                      >{{
                        qrScan.index === index &&
                        qrScan.batchIndex === batchIndex
                          ? 'Stop Scan Qr'
                          : 'Scan Qr'
                      }}</base-button
                    >
                  </td>
                  <td :class="classes.td">
                    <button
                      v-if="item.batches.length > 1"
                      class="flex w-full justify-center"
                      @click="onDeleteBatch(index, batchIndex)"
                    >
                      <Icon
                        icon="heroicons:x-mark-20-solid"
                        class="mx-auto h-4 w-4 cursor-pointer text-red-300 hover:text-red-600"
                      />
                    </button>
                  </td>
                  <td></td>
                </tr>
              </template>
            </template>
          </datatable>
        </div>
      </base-card>

      <div class="flex justify-end gap-2">
        <base-button :disabled="!validForSubmit" @click="visibleConfirm = true"
          >Simpan</base-button
        >
        <base-button
          color="white"
          :to="{ name: 'gudang.pengeluaran.dari-supplier' }"
          >Kembali</base-button
        >
      </div>
    </div>

    <view-stock-movement-modal
      with-first-packet-product
      with-batch
      :visible="detailModal.visible"
      :stock-movement-id="detailModal.stockMovementId"
      @deleted="$router.push({ name: 'gudang.pengeluaran.dari-supplier' })"
      @validated="$router.push({ name: 'gudang.pengeluaran.dari-supplier' })"
      @close="$router.push({ name: 'gudang.pengeluaran.dari-supplier' })"
    />

    <base-confirm
      :visible="visibleConfirm"
      icon-type="badge"
      type="success"
      title="Validasi Pengeluaran"
      message="Validasi pengeluaran supplier ke pengiriman"
      @close="visibleConfirm = false"
      @confirmed="onSubmit"
    />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import ViewStockMovementModal from '@/components/stock-movement/view-stock-movement-modal.vue';
import { mapActions } from 'vuex';
import BaseConfirm from '@/components/base/BaseConfirm.vue';
import dayjs from 'dayjs';

export default {
  mixins: [requestMixin],
  components: { ViewStockMovementModal, BaseConfirm },
  data() {
    return {
      detailModal: {
        visible: false,
        stockMovementId: null,
      },
      loadingStockMovement: false,
      loadingSubmit: false,
      loadingProductBoxes: false,
      loadingMovementDetail: false,
      stockMovement: {
        data: {
          attributes: {},
          relationships: {
            'origin-office': {
              data: null,
            },
            order: {
              data: null,
            },
          },
        },
        included: [],
      },
      qrScan: {
        index: null,
        batchIndex: null,
      },
      productBoxes: [],
      shipmentForm: [],
      visibleConfirm: false,
    };
  },
  computed: {
    loading() {
      return (
        this.loadingStockMovement ||
        this.loadingSubmit ||
        this.loadingProductBoxes ||
        this.loadingMovementDetail
      );
    },
    movementDetails() {
      return this.stockMovement.data.attributes.packets[0].products;
    },
    tableColumns() {
      return [
        { id: 'code', name: 'Kode Barang' },
        { id: 'name', name: 'Nama Barang / Batch' },
        { id: 'qty', name: 'Jumlah' },
        { id: 'price', name: 'Harga / Tgl Kadaluarsa' },
        { id: 'total_price', name: 'Total Harga' },
        { id: 'action', name: 'Aksi', theadClass: 'text-center' },
      ];
    },
    validForSubmit() {
      return this.shipmentForm.every((item) => {
        if (parseInt(item.qty) > item.productQty) {
          return false;
        }

        const allBatchValid = item.batches.every((batch) => {
          return !!batch.no && !!batch.qty && !!batch.expire;
        });

        if (!allBatchValid) {
          return false;
        }

        return (
          item.batches.reduce(
            (total, batch) => total + parseInt(batch.qty),
            0
          ) === parseInt(item.qty)
        );
      });
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async loadStockMovement() {
      this.loadingStockMovement = true;

      const [res, err] = await this.request(
        `/api/v1/stock-movements/${this.$route.params.id}`,
        {
          params: {
            include: 'order,origin-office',
            'fields[stock-movements]': 'packets,updatedAt,order,origin-office',
            'fields[orders]': 'origin_code,updatedAt',
            'fields[offices]': 'code,name',
          },
        }
      );

      if (!err) {
        this.stockMovement = res;

        await this.loadOrderProductBoxes();

        this.setShipmentForm();
      }

      this.loadingStockMovement = false;
    },
    async loadOrderProductBoxes() {
      this.loadingProductBoxes = true;

      const [res, err] = await this.request(
        `/api/v1/orders/${this.stockMovement.data.relationships.order.data.id}/-actions/product-boxes`
      );

      if (!err) {
        this.productBoxes = res;
      }

      this.loadingProductBoxes = false;
    },
    onAddBatch(index) {
      this.shipmentForm[index].batches.push({
        id: null,
        no: null,
        originalQty: null,
        qty: null,
        expire: null,
      });
    },
    async onDeleteBatch(index, batchIndex) {
      const batch = this.shipmentForm[index].batches[batchIndex];

      if (!batch.id) {
        this.shipmentForm[index].batches.splice(batchIndex, 1);
      } else {
        this.loadingMovementDetail = true;

        const [, err] = await this.request(
          `/api/v1/product-batches/${batch.id}`,
          {
            method: 'delete',
          }
        );

        if (err) {
          this.createAlert({
            status: 'error',
            data: this.getRequestErrorMessage(err),
          });
        } else {
          this.shipmentForm[index].batches.splice(batchIndex, 1);
        }

        this.loadingMovementDetail = false;
      }
    },
    async onSubmit() {
      this.loadingSubmit = true;

      const [, err] = await this.request(
        `/api/v1/stock-movements/${this.stockMovement.data.id}/-actions/validate-shipment`,
        {
          method: 'patch',
        }
      );

      if (!err) {
        this.visibleConfirm = false;
        this.detailModal.stockMovementId = this.stockMovement.data.id;
        this.detailModal.visible = true;
      }

      this.loadingSubmit = false;
    },
    onStartScan(index, batchIndex) {
      if (
        this.qrScan.index === index &&
        this.qrScan.batchIndex === batchIndex
      ) {
        this.qrScan.index = null;
        this.qrScan.batchIndex = null;
      } else {
        this.qrScan.index = index;
        this.qrScan.batchIndex = batchIndex;
      }
    },
    onQrScanned(e) {
      if (this.qrScan.batchIndex !== null) {
        const { text: qrCode } = e.detail;

        const product = this.shipmentForm[this.qrScan.index].qrcodes.find(
          (qr) => qr.qrcode === qrCode
        );

        if (product) {
          this.shipmentForm[this.qrScan.index].batches[
            this.qrScan.batchIndex
          ].qty += product.qty;

          this.onChangeBatch(this.qrScan.index, this.qrScan.batchIndex);
        } else {
          this.submitScanProductQr(
            this.qrScan.index,
            this.qrScan.batchIndex,
            qrCode
          );
        }
      }
    },
    onChangeDetailQty(index) {
      const max = this.movementDetails[index].product_qty;
      const originalQty =
        this.movementDetails[index].shipped_product_qty ||
        this.movementDetails[index].product_qty;
      const qty = Number(this.shipmentForm[index].qty);

      if (isNaN(qty) || qty < 1 || qty > max) {
        this.shipmentForm[index].qty = originalQty;
      } else {
        this.updateMovementDetail(index, {
          attributes: {
            shipped_product_qty: qty,
          },
        });
      }
    },
    async onChangeBatch(index, batchIndex) {
      this.loadingMovementDetail = true;

      const movementDetail = this.movementDetails[index];
      const batch = this.shipmentForm[index].batches[batchIndex];
      const update = batch.id !== null;

      const [res, err] = await this.request(
        `/api/v1/product-batches${update ? `/${batch.id}` : '/'}`,
        {
          method: batch.id ? 'patch' : 'post',
          data: {
            data: {
              type: 'product-batches',
              ...(update ? { id: batch.id } : {}),
              attributes: {
                no_batch: batch.no,
                product_qty: batch.qty ? Number(batch.qty) : 0,
                expires_at: batch.expire,
              },
              relationships: {
                'movement-detail': {
                  data: {
                    type: 'movement-details',
                    id: movementDetail.uuid,
                  },
                },
              },
            },
          },
        }
      );

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
        this.shipmentForm[index].batches[batchIndex].qty = batch.originalQty;
      } else {
        this.shipmentForm[index].batches[batchIndex].id = res.data.id;
        this.shipmentForm[index].batches[batchIndex].qty =
          res.data.attributes.product_qty;
        this.shipmentForm[index].batches[batchIndex].originalQty =
          res.data.attributes.product_qty;
      }

      this.loadingMovementDetail = false;
    },
    async submitScanProductQr(index, batchIndex, qrcode) {
      this.loadingMovementDetail = true;

      const movementDetail = this.movementDetails[index];
      const batch = this.shipmentForm[index].batches[batchIndex];

      const [res, err] = await this.request(
        `/api/v1/product-batches/-actions/scan-product-qr-code`,
        {
          method: 'post',
          data: {
            batch_id: batch.id,
            movement_detail_id: movementDetail.uuid,
            qrcode,
          },
        }
      );

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      } else {
        this.shipmentForm[index].batches[batchIndex].id = res.data.id;
        this.shipmentForm[index].batches[batchIndex].qty =
          res.data.attributes.product_qty;
        this.shipmentForm[index].batches[batchIndex].originalQty =
          res.data.attributes.product_qty;
      }

      this.loadingMovementDetail = false;
    },
    setShipmentForm() {
      this.shipmentForm = this.movementDetails.map((movementDetail) => {
        const boxes = this.productBoxes
          .filter((box) => box.product.uuid === movementDetail.product_id)
          .map((box) => ({ qrcode: box.qrcode, qty: box.product_count }));

        return {
          orderDetailId: movementDetail.id,
          productCode: movementDetail.product_code,
          productType: movementDetail.product_type,
          productName: movementDetail.product_name,
          productQty: movementDetail.product_qty,
          qty: movementDetail.shipped_product_qty || movementDetail.product_qty,
          productPrice: movementDetail.product_price,
          totalPrice: movementDetail.total_price,
          qrcodes: [
            ...(movementDetail.product_qrcode &&
            movementDetail.product_qrcode_active
              ? [{ qrcode: movementDetail.product_qrcode, qty: 1 }]
              : []),
            ...boxes,
          ],
          batches: movementDetail.batches.length
            ? movementDetail.batches.map((batch) => ({
                id: batch.uuid,
                no: batch.no_batch,
                originalQty: batch.product_qty < 1 ? null : batch.product_qty,
                qty: batch.product_qty < 1 ? null : batch.product_qty,
                expire:
                  dayjs(batch.expires_at).unix() > 1
                    ? dayjs(batch.expires_at).format('YYYY-MM-DD')
                    : null,
              }))
            : [
                {
                  id: null,
                  no: null,
                  qty: null,
                  expire: null,
                },
              ],
        };
      });
    },
    async updateMovementDetail(index, params) {
      this.loadingMovementDetail = true;

      const movementDetail = this.movementDetails[index];

      const [, err] = await this.request(
        `/api/v1/movement-details/${movementDetail.uuid}`,
        {
          method: 'patch',
          data: {
            data: {
              type: 'movement-details',
              id: movementDetail.uuid,
              attributes: {
                ...params.attributes,
              },
            },
          },
        }
      );

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      }

      this.loadingMovementDetail = false;
    },
  },
  async created() {
    this.loadStockMovement();
  },
  mounted() {
    document.addEventListener('barcode-scan', this.onQrScanned);
  },
  destroyed() {
    document.removeEventListener('barcode-scan', this.onQrScanned);
  },
};
</script>
